const iframeStyles = {
        iframeWrapper: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '100px',
        },
        iframe: {
            border: 'none',
            frameBorder: 'none',
            overflow: 'hidden',
            width: '100vw',
            maxWidth: '100%',
            margin: '0 auto',
            minHeight: '100vh',

            "@media (min-width: 768px)": {
                paddingTop: '100px',
                width: "700px"
            },
    },
};

export default iframeStyles