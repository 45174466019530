import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import iframeStyles from "assets/style/iframe.js"

const UseStyles = makeStyles(styles);
const UseIframeStyles = makeStyles(iframeStyles);

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  const iframeClass = UseIframeStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
            <div className={iframeClass.iframeWrapper}>
                <iframe className={iframeClass.iframe} src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Feffathakluczbork%2Fposts%2F2523918131068160" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" title="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Feffathakluczbork%2Fposts%2F2523918131068160"></iframe>
            </div>
        </div>
      </div>
    </Layout>
  );
}